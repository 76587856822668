import { ONE_DAY,
    ONE_HOUR,
    ONE_MONTH
 } from "./constants/chart-defaults";

export function timeToLocal(originalTime) {
    const d = new Date(originalTime * 1000);
    return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000;
};

export const getCandleConfig = (tag) => {
    const Intervals = {
        M1: {
            interval: 1, // 1 min
            initialTimeFrame: 2 * ONE_HOUR,
            timeFrame: ONE_DAY,         // for 1 day ~ 533
        },
        M5: {
            interval: 5, // 5 min
            initialTimeFrame: 6 * ONE_HOUR,
            timeFrame: 3 * ONE_DAY,     // for 1 day ~ 108, for 3 days ~ 665
        },
        M15: {
            interval: 15, // 15 min
            initialTimeFrame: 18 * ONE_HOUR,
            timeFrame: 9 * ONE_DAY,     // for 1 day ~ 37, for 9 days ~ 618
        },
        M30: {
            interval: 30, // 30 min
            initialTimeFrame: 36 * ONE_HOUR,
            timeFrame: 18 * ONE_DAY,    // for 1 day ~ 18, for 18 days ~ 642
        },
        H1: {
            interval: 60, // 60 min
            initialTimeFrame: 3 * ONE_DAY,
            timeFrame: 1 * ONE_MONTH,   // for 1 month ~ 513
        },
        H4: {
            interval: 4 * 60, // 4 hours in min
            initialTimeFrame: 12 * ONE_DAY,
            timeFrame: 4 * ONE_MONTH,   // for 4 month ~ 530
        },
        D1: {
            interval: 24 * 60, // 24 hours in min
            initialTimeFrame: 3 * ONE_MONTH,
            timeFrame: 6 * ONE_MONTH,
        },
        W1: {
            interval: 7 * 24 * 60, // 24 hours in min
            initialTimeFrame: 12 * ONE_MONTH,
            timeFrame: 12 * ONE_MONTH,
        },
        MN: {
            interval: 30 * 24 * 60, // 24 hours in min
            initialTimeFrame: 2 * 12 * ONE_MONTH,
            timeFrame: 5 * 12 * ONE_MONTH,
        },
    };
    return Intervals[tag];
};


export const validateAndFixData = (data) => {
    // Use a Map to remove duplicates (keyed by `datetime`)
    const uniqueDataMap = new Map();
    data.forEach(item => uniqueDataMap.set(item.datetime, item));
    
    // Convert back to an array and sort by `datetime`
    const validatedData = Array.from(uniqueDataMap.values()).sort((a, b) => a.datetime - b.datetime);
    return validatedData;
}
