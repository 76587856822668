import { MOVING_AVERAGE_DEFAULT_PERIOD } from "../constants/chart-defaults";


export function calculateMovingAverageSeriesData(candleData, movingAvgData, params = { period: MOVING_AVERAGE_DEFAULT_PERIOD }) {
    let { period } = params;

    // in case user set this params to zero
    if (!period) {
        period = MOVING_AVERAGE_DEFAULT_PERIOD
    }

    movingAvgData.length = 0;
    for (let i = 0; i < candleData.length; i++) {
        if (i < period) {
            // Provide whitespace data points until the MA can be calculated
            movingAvgData.push({ time: candleData[i].time });
        } else {
            // Calculate the moving average, slow but simple way
            let sum = 0;
            for (let j = 0; j < period; j++) {
                sum += candleData[i - j].close;
            }
            const maValue = sum / period;
            movingAvgData.push({ time: candleData[i].time, value: maValue });
        }
    }
}
