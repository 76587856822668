import {createStore, combineReducers, applyMiddleware} from 'redux';
import {thunk} from 'redux-thunk';
import priceReducer from './priceReducer';
import websocketReducer from './websocketReducer';


const rootReducer = combineReducers({
  price: priceReducer,
  socket: websocketReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));