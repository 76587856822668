import { SOCKET_IS_AUTH, SOCKET_IS_REQUEST_IN_PROGRESS, SOCKET_SEND_JSON_MESSAGE, SOCKET_UPDATE_EARLIEST_TIMESTAMP } from "./types";

export const setJsonMessageToSend = (msg) => {
  return {
    type: SOCKET_SEND_JSON_MESSAGE,
    payload: msg,
  };
};

export const setIsAuth = (isAuth) => {
  return {
    type: SOCKET_IS_AUTH,
    payload: isAuth,
  };
};

export const updateEarliestTimestamp = (earliestTimestamp) => {
  return {
    type: SOCKET_UPDATE_EARLIEST_TIMESTAMP,
    payload: earliestTimestamp,
  };
};

export const setIsRequestInProgress = (isRequestInProgress) => {
  return {
    type: SOCKET_IS_REQUEST_IN_PROGRESS,
    payload: isRequestInProgress,
  };
};
