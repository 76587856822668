import { getCandleConfig } from "../helpers";
import {
  PRICE_CHART_SYMBOL,
  PRICE_DATA_RECEIVE,
  PRICE_HISTORY_RECEIVE,
  PRICE_OPEN_POSITION_LIST,
} from "./types";

export const receivePrice = (price) => {
  return {
    type: PRICE_DATA_RECEIVE,
    payload: price,
  };
};

export const receivePriceHistory = (history) => {
  return {
    type: PRICE_HISTORY_RECEIVE,
    payload: history,
  };
};

export const setChartSymbolAndChartTag = ({ symbol, chartTag }) => {
  const { interval, timeFrame: candleNum } = getCandleConfig(chartTag);
  // console.log("setChartSymbolandTag:", symbol, chartTag, interval);
  return {
    type: PRICE_CHART_SYMBOL,
    payload: { symbol, interval },
  };
};

export const receiveOpenPositionList = (openPositionList) => {
  return {
    type: PRICE_OPEN_POSITION_LIST,
    payload: openPositionList,
  };
};
