import { validateAndFixData } from "../helpers";
import {
  PRICE_CHART_SYMBOL,
  PRICE_DATA_RECEIVE,
  PRICE_HISTORY_RECEIVE,
  PRICE_OPEN_POSITION_LIST,
} from "./types";

const initialState = {
  quotes: {},
  symbol: "",
  interval: 1,
  price: null,
  history: [],
  openPositionList: [],
};

const pushPrice = ({ price, history, interval }) => {
  if (history && history.length > 0 && typeof price !== "undefined") {
    if (price.timestamp !== 0) {
      const prevDate = new Date(
        history.length === 0 ? 0 : history[history.length - 1].x * 1000
      );
      const date = new Date(price.timestamp * 1000);
      if (
        (date.getMinutes() !== prevDate.getMinutes() && interval === 1) ||
        (Number.parseInt(date.getMinutes() / 5, 10) !==
          Number.parseInt(prevDate.getMinutes() / 5, 10) &&
          interval === 5) ||
        (Number.parseInt(date.getMinutes() / 15, 10) !==
          Number.parseInt(prevDate.getMinutes() / 15, 10) &&
          interval === 15) ||
        (Number.parseInt(date.getMinutes() / 30, 10) !==
          Number.parseInt(prevDate.getMinutes() / 30, 10) &&
          interval === 30) ||
        (date.getHours() !== prevDate.getHours() && interval === 60) ||
        (Number.parseInt(date.getHours() / 4, 10) !==
          Number.parseInt(prevDate.getHours() / 4, 10) &&
          interval === 240) ||
        (date.getDate() !== prevDate.getDate() && interval === 1440) ||
        (Number.parseInt(date.getDate() / 7, 10) !==
          Number.parseInt(prevDate.getDate() / 7, 10) &&
          interval === 10080) ||
        (date.getMonth() !== prevDate.getMonth() && interval === 40001)
      ) {
        history.push({
          x: price.timestamp,
          open:
            history.length === 0
              ? price.bid
              : history[history.length - 1].close,
          close: price.bid,
          low: price.bid,
          high: price.bid,
        });
      } else {
        if (history.length) {
          history[history.length - 1].close = price.bid;
        }
        if (price.bid > history[history.length - 1].high) {
          history[history.length - 1].high = price.bid;
        }
        if (price.bid < history[history.length - 1].low) {
          history[history.length - 1].low = price.bid;
        }
      }
    }
  }
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRICE_DATA_RECEIVE: {
      const quotes = { ...state.quotes, [payload.symbol]: payload };

      const newState = payload.symbol === state.symbol
        ? { ...state, price: payload }
        : state;

      pushPrice(newState);

      return { ...newState, quotes };
    }

    case PRICE_CHART_SYMBOL: {
      return state.symbol !== payload.symbol
        ? {
            ...state,
            symbol: payload.symbol,
            interval: payload.interval,
          }
        : state;
    }

    case PRICE_HISTORY_RECEIVE: {
      const validatedHistory = validateAndFixData([...payload, ...state.history]);

      return {
        ...state,
        history: validatedHistory,
        price: state.quotes[state.symbol],
      };
    }
    case PRICE_OPEN_POSITION_LIST: {
        return { ...state, openPositionList: payload };
    }
    default:
      return state;
  }
};
