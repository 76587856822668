import { BOLLINGER_DEFAULT_PERIOD, BOLLINGER_DEFAULT_STD } from "../constants/chart-defaults";
// import { timeToLocal } from "../helpers";

function SMA(sticks, index, period) {
    let sumClosePrice = 0;
    const first_index = Math.max(index - period + 1, 0);
    const len = index - first_index + 1;
    for (let i = first_index; i <= index; i++) {
      sumClosePrice += sticks[i].close;
    }
    return len ? sumClosePrice / len : sticks[index].close;
  }
  
  function StdDev(sticks, index, period, avg) {
    // SQRT (SUM ((CLOSE – SMA (CLOSE, N))^2, N)/N)
    let sumSqrDev = 0;
    const first_index = Math.max(index - period + 1, 0);
    const len = index - first_index + 1;
    for (let i = first_index; i <= index; i++) {
      sumSqrDev += Math.pow(sticks[i].close - avg, 2);
    }
    return len ? Math.sqrt(sumSqrDev / len) : 0;
  }
  
  export function bollinger(cdlValues, bollingerData, params={period: BOLLINGER_DEFAULT_PERIOD, std: BOLLINGER_DEFAULT_STD}) {
    let {period, std} = params;
    if (!period){
      period = BOLLINGER_DEFAULT_PERIOD;
    }
    if(!std){
      std = BOLLINGER_DEFAULT_STD;
    }

    bollingerData.ml = [];
    bollingerData.tl = [];
    bollingerData.bl = [];

  
    for (let i = 0; i < cdlValues.length; i++) {
      // middle-line
      const ml = SMA(cdlValues, i, period);
  
      const std_dev = StdDev(cdlValues, i, period, ml);
      // top-line
      const tl = ml + std * std_dev;
      // bottom-line
      const bl = ml - std * std_dev;
  
      bollingerData.ml.push({time: cdlValues[i].time, value: ml});
      bollingerData.tl.push({time: cdlValues[i].time, value: tl});
      bollingerData.bl.push({time: cdlValues[i].time, value: bl});
    }
  }
  